import { useOverlayContext } from 'seagull';
import { ErrorText } from "../../constants";
import { handleRequest } from "../../infra/utils";
export const useSendOTPCode = () => {
  const overlayContext = useOverlayContext();
  const sendOTPCode = async _ref => {
    let {
      requestOTP,
      onTooManyRequests,
      showSuccessToast
    } = _ref;
    const result = await handleRequest(requestOTP());
    if (result.success) {
      showSuccessToast && overlayContext.showSuccessToast('We have sent you a new verification code.', 'otp-request-success-toast');
    } else {
      if (result.error.isTooManyRequestError()) {
        onTooManyRequests();
        return;
      }
      overlayContext.showErrorToast(ErrorText.UnexpectedErrorTryAgain, 'otp-request-error-toast');
    }
  };
  return {
    sendOTPCode
  };
};