import { BillingTriggerType } from "../../types/api.model";
import { RecurrencePeriodTypes } from "./model";
import { formatNumTimes } from "../../infra/utils";
export function isRecurring(billing) {
  return billing.trigger === BillingTriggerType.Ongoing || billing.trigger === BillingTriggerType.RepeatableManual;
}
export function isAutomatic(billing) {
  return billing.trigger === BillingTriggerType.OnApproval || billing.trigger === BillingTriggerType.Ongoing;
}
export function isManual(billing) {
  return billing.trigger === BillingTriggerType.OneTimeManual || billing.trigger === BillingTriggerType.RepeatableManual;
}
export function isOnApproval(billing) {
  return billing.trigger === BillingTriggerType.OnApproval;
}
export function isOneTimeManual(billing) {
  return billing.trigger === BillingTriggerType.OneTimeManual;
}
export function isOngoing(billing) {
  return billing.trigger === BillingTriggerType.Ongoing;
}
export function isRepeatableManual(billing) {
  return billing.trigger === BillingTriggerType.RepeatableManual;
}
export function billingDayOfMonth(billing) {
  return isRecurring(billing) ? billing.billingDayOfMonth : null;
}
export function billingDayOfWeek(billing) {
  return isRecurring(billing) ? billing.billingDayOfWeek : null;
}
export function maxChargesEnabled(billing) {
  return isRecurring(billing) && billing.maxCharges != null ? billing.maxCharges.enabled : false;
}
export function maxCharges(billing) {
  return isRecurring(billing) ? billing.maxCharges : null;
}
export function maxChargesLimit(billing) {
  return isRecurring(billing) && billing.maxCharges != null ? billing.maxCharges.limit : null;
}
export function formatMaxChargesLimit(billing) {
  return formatNumTimes(maxChargesLimit(billing));
}
export function trigger(billing) {
  return billing.trigger;
}
export function period(billing) {
  return isRecurring(billing) ? billing.recurrencePeriod : null;
}
export function isBilledUpfront(billing) {
  return isRecurring(billing) ? billing.isBilledUpfront : false;
}
export function isSkipBillingOnAcceptance(billing) {
  return isOngoing(billing) ? billing.billUpfrontState.skipBillingOnAcceptance : false;
}
export function isYearly(billing) {
  return isRecurring(billing) && billing.recurrencePeriod === RecurrencePeriodTypes.Yearly;
}
export function isQuarterly(billing) {
  return isRecurring(billing) && billing.recurrencePeriod === RecurrencePeriodTypes.Quarterly;
}
export function isMonthly(billing) {
  return isRecurring(billing) && billing.recurrencePeriod === RecurrencePeriodTypes.Monthly;
}
export function isWeekly(billing) {
  return isRecurring(billing) && billing.recurrencePeriod === RecurrencePeriodTypes.Weekly;
}
export function isBiWeekly(billing) {
  return isRecurring(billing) && billing.recurrencePeriod === RecurrencePeriodTypes.BiWeekly;
}
export function isPaused(billing) {
  return isOngoing(billing) && billing.isPaused;
}
export function isProrated(billing) {
  return isOngoing(billing) && billing.isProrated;
}
export function periodRequiresBillingDayOfWeek(period) {
  return [RecurrencePeriodTypes.Weekly, RecurrencePeriodTypes.BiWeekly].includes(period);
}
export function isBilledImmediatelyWhenApproved(billing) {
  return isOnApproval(billing) || isOngoing(billing) && billing.isBilledUpfront && !billing.isPaused && !billing.billUpfrontState.skipBillingOnAcceptance;
}