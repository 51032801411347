import { billableStateModel, serviceBillingModel } from "../../models";
import { stringifyServiceCost } from "../../pages/AgreementMinisite/BillingHub/BillingControl/BillingControl.utils";
export const filteredRelationshipManualServices = (services, controlData) => {
  return services.filter(service => {
    return serviceBillingModel.isManual(service.billing) && controlData.manualServices[service.id] && billableStateModel.isBillable(controlData.manualServices[service.id].billableState);
  });
};
export const mapRelationshipSummariesToAgreementOptions = relationshipSummaries => {
  return relationshipSummaries.map(summary => ({
    label: summary.title,
    value: summary.id
  }));
};
export const mapServicesToServiceOptions = (services, controlData) => {
  return filteredRelationshipManualServices(services, controlData).map(service => {
    const {
      cost
    } = service;
    const {
      costDisplay,
      unitCapDisplay
    } = stringifyServiceCost(cost);
    return {
      label: service.name,
      value: unitCapDisplay ? "".concat(costDisplay, " \u2022 ").concat(unitCapDisplay) : "".concat(costDisplay),
      id: service.id
    };
  });
};