// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E4jR3{display:flex;flex-direction:column;justify-content:center}.GbY2y{display:flex;flex-direction:column;justify-content:flex-start;margin-top:40px}.hYWys{margin-bottom:8px}.dfKCk{height:1px;background:#d4d4e0;margin:57px 0 20px 0}.AXN35{margin-top:66px;margin-bottom:8px}.JBauA{display:flex;align-items:center;gap:20px;margin-bottom:28px}.opOO9{max-width:720px;background:#fcfcfd;box-shadow:0px 4px 12px rgba(40,40,99,.06);border-radius:8px;text-align:start;padding:16px 28px}.opOO9 .muJ2F{position:relative;margin-bottom:4px;padding-left:20px}.opOO9 .muJ2F::before{content:"";display:block;position:absolute;top:10px;left:4px;width:4px;min-width:4px;height:4px;border-radius:50%;background-color:currentColor}.W917Q{margin-top:62px;margin-bottom:20px}.KezLS{display:flex;gap:20px;justify-content:center}.slzEO{width:230px;height:140px;display:flex;flex-direction:column;gap:20px;justify-content:center;align-items:center;background:#f3f5ff;border:1px solid #dae0ff;border-radius:8px;box-shadow:0px 4px 12px rgba(40,40,99,.06)}.slzEO:hover,.slzEO:active{border-color:#6942f8;box-shadow:0px 4px 14px rgba(40,40,99,.24)}.BLKD7{width:210px;height:118px;display:flex;flex-direction:column;gap:20px;justify-content:center;align-items:center;background:#f3f5ff;border:1px solid #dae0ff;border-radius:8px;box-shadow:0px 4px 12px rgba(40,40,99,.06)}.BLKD7:hover,.BLKD7:active{border-color:#6942f8;box-shadow:0px 4px 14px rgba(40,40,99,.24)}.iLF2E{width:100%;height:100%;display:flex;align-items:center;justify-content:center}.n6BVY{display:flex;flex-direction:column;gap:8px;align-items:center}.kWqLJ{margin-top:30px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"sh10": `0px 4px 12px rgba(40,40,99,.06)`,
	"sh20": `0px 4px 16px rgba(40,40,99,.08)`,
	"sh40": `0px 4px 24px rgba(40,40,99,.12)`,
	"sh60": `0px 4px 14px rgba(40,40,99,.24)`,
	"allSetPageWrapper": `E4jR3`,
	"confirmationWrapper": `GbY2y`,
	"thankYouText": `hYWys`,
	"sep": `dfKCk`,
	"desktopTitle": `AXN35`,
	"mobileTitle": `JBauA`,
	"textBlock": `opOO9`,
	"listItem": `muJ2F`,
	"nextStepsTitle": `W917Q`,
	"nextStepsRow": `KezLS`,
	"nextStepBtn": `slzEO`,
	"nextStepBlock": `BLKD7`,
	"reviewerRoot": `iLF2E`,
	"reviewerInner": `n6BVY`,
	"createVendorServiceTextBlock": `kWqLJ`
};
export default ___CSS_LOADER_EXPORT___;
