import { ServiceStatusType, ServiceSource } from "../service/model";
import * as serviceCostModel from "../service-cost";
import * as serviceBillingModel from "../service-billing";
export function supportsPriceIncrease(_ref) {
  let {
    billing
  } = _ref;
  return serviceBillingModel.isRecurring(billing);
}
export function id(service) {
  return service.id;
}
export function cost(service) {
  return service.cost;
}
export function discount(service) {
  return serviceCostModel.discount(service.cost);
}
export function billingTrigger(service) {
  return service.billing;
}
export function recurrencePeriod(billing) {
  return billing.recurrencePeriod;
}
export function billingDayOfMonth(service) {
  return serviceBillingModel.billingDayOfMonth(service.billing);
}
export function billingDayOfWeek(service) {
  return serviceBillingModel.billingDayOfWeek(service.billing);
}
export function maxChargesEnabled(service) {
  return serviceBillingModel.maxChargesEnabled(service.billing);
}
export function maxCharges(service) {
  return serviceBillingModel.maxCharges(service.billing);
}
export function maxChargesLimit(service) {
  return serviceBillingModel.maxChargesLimit(service.billing);
}
export function name(service) {
  return service.name;
}
export function price(_ref2) {
  let {
    cost
  } = _ref2;
  return serviceCostModel.price(cost);
}
export function minPrice(service) {
  return serviceCostModel.minPrice(service.cost);
}
export function unitName(_ref3) {
  let {
    cost
  } = _ref3;
  return serviceCostModel.unitName(cost);
}
export function unitCap(_ref4) {
  let {
    cost
  } = _ref4;
  return serviceCostModel.unitCap(cost);
}
export function finalPrice(_ref5, quantity) {
  let {
    cost
  } = _ref5;
  return serviceCostModel.finalPrice(cost, quantity);
}
export function applyDiscount(_ref6) {
  let {
    cost
  } = _ref6;
  return serviceCostModel.applyDiscount(cost);
}
export function minPriceFinal(_ref7) {
  let {
    cost
  } = _ref7;
  return serviceCostModel.minPriceFinal(cost);
}
export function changes(service) {
  return isAmended(service.status) ? service.status.amended.changes : isCompleted(service.status) ? service.status.completed.changes || null : null;
}
export function isBilledUpfront(service) {
  return serviceBillingModel.isBilledUpfront(service.billing);
}
export function isSkipBillingOnAcceptance(service) {
  return serviceBillingModel.isSkipBillingOnAcceptance(service.billing);
}
export const isQuarterly = service => serviceBillingModel.isQuarterly(service.billing);
export const isMonthly = service => serviceBillingModel.isMonthly(service.billing);
export const isWeekly = service => serviceBillingModel.isWeekly(service.billing);
export const isBiWeekly = service => serviceBillingModel.isBiWeekly(service.billing);
export function isAmended(status) {
  return status.type === ServiceStatusType.Amended;
}
export function isApproved(status) {
  return status.type === ServiceStatusType.Approved;
}
export function isAwaitingApproval(status) {
  return status.type === ServiceStatusType.AwaitingApproval;
}
export function requiresClientApproval(service) {
  return isAmended(service.status) || isAwaitingApproval(service.status);
}
export function isActive(service) {
  return isApproved(service.status) || isAmended(service.status);
}
export function isPaused(service) {
  return serviceBillingModel.isPaused(service.billing);
}
export function isProrated(service) {
  return serviceBillingModel.isProrated(service.billing);
}
export function hasAmendments(service) {
  const serviceChanges = changes(service);
  return !!serviceChanges && Object.keys(serviceChanges).some(k => serviceChanges[k] != null);
}
export function note(service) {
  return isAmended(service.status) ? service.status.amended.note : isAwaitingApproval(service.status) ? service.status.awaitingApproval.note : null;
}
export function approvalMethod(service) {
  return isAmended(service.status) ? service.status.amended.approvalMethod : isAwaitingApproval(service.status) ? service.status.awaitingApproval.approvalMethod || null : null;
}
export function hasAutoAmendments(service) {
  const apMethod = approvalMethod(service);
  return !!apMethod && apMethod.isAutoApproval();
}
export function hasApprovalMethod(service) {
  return !!approvalMethod(service);
}
export function autoApprovalDate(service) {
  var _apMethod$autoApprova;
  const apMethod = approvalMethod(service);
  return apMethod === null || apMethod === void 0 || (_apMethod$autoApprova = apMethod.autoApprovalDate()) === null || _apMethod$autoApprova === void 0 ? void 0 : _apMethod$autoApprova.getTime();
}
export function isMandatory(participation) {
  return participation.type === 'mandatory';
}
export function isOptional(participation) {
  return participation.type === 'optional';
}
export function isTerminated(status) {
  return status.type === ServiceStatusType.Terminated;
}
export function isCompleted(status) {
  return status.type === ServiceStatusType.Completed;
}
export function isCanceled(status) {
  return status.type === ServiceStatusType.Canceled;
}
export function effectiveDate(_ref8) {
  let {
    status
  } = _ref8;
  return isAwaitingApproval(status) || isCanceled(status) ? null : status.date;
}
export function amendmentDate(_ref9) {
  let {
    status
  } = _ref9;
  return isAwaitingApproval(status) ? status.awaitingApproval.addedOn : isAmended(status) ? status.amended.amendedOn : null;
}
export function priceIncrease(_ref10) {
  let {
    cost
  } = _ref10;
  return serviceCostModel.priceIncrease(cost);
}
export function nextYearPrice(service) {
  return serviceCostModel.nextYearPrice(service.cost);
}
export function integratedClassId(service) {
  return service.integrations.qboIntegratedClassId();
}
export function financialCentsIntegration(service) {
  return service.integrations.financialCentsIntegration();
}
export const karbonIntegration = service => service.integrations.karbonIntegration();
export function isFromAmendment(service) {
  return service.source === ServiceSource.Amendment;
}