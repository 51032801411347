import produce from 'immer';
import { generateUniqueId } from 'seagull/utils/generateUniqueId';
import { ServiceStatusType } from "./model";
import * as selectors from "./selectors";
import { ApprovalMethodDateModel } from "../ApprovalMethodDateModel";
import { IntegratedClassType, ServiceIntegrationsModel } from "../ServiceIntegrations";
import { PriceIncreaseModel } from "../PriceIncreaseModel";
export function makeChanges(changes) {
  return {
    name: changes.name != null ? changes.name : null,
    description: changes.description != null ? changes.description : null,
    cost: changes.cost != null ? changes.cost : null,
    billing: changes.billing != null ? changes.billing : null
  };
}
export function makeAwaitingApprovalStatus() {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    type: ServiceStatusType.AwaitingApproval,
    awaitingApproval: {
      addedOn: options.addedOn || new Date(),
      approvalMethod: options.approvalMethod || ApprovalMethodDateModel.makeManual(),
      note: options.note || null
    }
  };
}
export function makeAmendedStatus() {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    type: ServiceStatusType.Amended,
    date: options.date || new Date(),
    amended: {
      amendedOn: options.amendedOn || new Date(),
      changes: makeChanges(options.changes || {}),
      approvalMethod: options.approvalMethod || ApprovalMethodDateModel.makeManual(),
      note: options.note || null
    }
  };
}
export function makeApprovedStatus(date) {
  return {
    type: ServiceStatusType.Approved,
    date
  };
}
export function makeCompletedStatus(date, changes) {
  const status = {
    type: ServiceStatusType.Completed,
    date,
    completed: {}
  };
  if (changes) {
    status.completed.changes = makeChanges(changes);
  }
  return status;
}
export function makeTerminatedStatus(date) {
  return {
    type: ServiceStatusType.Terminated,
    date
  };
}
export function makeCanceledStatus() {
  return {
    type: ServiceStatusType.Canceled
  };
}
export function makeService(input) {
  var _input$id, _input$isBillable, _input$serviceTemplat, _input$participation;
  let service = {
    id: (_input$id = input.id) !== null && _input$id !== void 0 ? _input$id : generateUniqueId('service'),
    name: input.name,
    description: input.description,
    status: input.status,
    cost: input.cost,
    billing: input.billing,
    isBillable: (_input$isBillable = input.isBillable) !== null && _input$isBillable !== void 0 ? _input$isBillable : false,
    serviceTemplateId: (_input$serviceTemplat = input.serviceTemplateId) !== null && _input$serviceTemplat !== void 0 ? _input$serviceTemplat : null,
    participation: (_input$participation = input.participation) !== null && _input$participation !== void 0 ? _input$participation : makeMandatoryParticipation(),
    integratedId: input.integratedId,
    integrations: input.integrations || ServiceIntegrationsModel.fromDTO(undefined),
    source: input.source
  };
  if (!selectors.supportsPriceIncrease(service)) {
    service = produce(service, s => {
      s.cost.priceIncrease = PriceIncreaseModel.makeNone();
    });
  }
  return service;
}
export function makeMandatoryParticipation() {
  return {
    type: 'mandatory'
  };
}
export function makeOptionalParticipation(includedByDefault) {
  return {
    type: 'optional',
    optional: {
      includedByDefault
    }
  };
}
export function makeIntegratedClassUnset() {
  return {
    type: IntegratedClassType.Unset
  };
}
export function makeIntegratedClassSet(id) {
  return {
    type: IntegratedClassType.Set,
    set: {
      id
    }
  };
}